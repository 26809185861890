<!--  -->
<template>
  <div class="footer">
    <div class="footerCenter">
      <div class="footerUrl">
        <ul class="flex-vw flex">
          <li v-for="(item, index) in urlList" class="flex" :title="item.name">
            <img :src="item.img" v-if="item.img" />
            <span @click="openPage(item, index)">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      messageFlag: false,
      urlList: [
        {
          name: "copyright@2019 xiaominet.com All Right Reserved 武汉小咪网络科技有限公司版权所有",
        },
        {
          name: "武汉市东湖新技术开发区金融港一路7号28栋6层608号",
        },
        {
          name: "站长统计",
          url: "https://www.cnzz.com/stat/website.php?web_id=1278896432",
        },
        {
          img: require("../../assets/img/footer/copy-footer.png"),
          name: "鄂公网安备42011502001211",
          url: "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011502001211",
        },
        {
          name: "鄂ICP备14013067号-8",
          url: "https://beian.miit.gov.cn/",
        } /*
                {
                    name: '鄂网文〔2018〕9552-285号',
                    url: 'https://qr.ccm.gov.cn/lic/433989a56f9d4ba7b1e053d606bea92f',
                },*/,
        {
          name: "鄂网文〔2021〕3912-166号",
          url: "/filing",
        },
        {
          name: "纠纷处理",
          url: "/dispute_resolution",
        },
        {
          name: "未成年人保护措施",
          url: "/juveniles",
        },
        {
          name: "网站内容审核规则",
          url: "/protocol_check",
        },

        {
          name: "中国互联网违法和不良信息举报中心",
          url: "https://www.12377.cn/",
        },
        {
          name: "中国扫黄打非-网上举报",
          url: "https://www.shdf.gov.cn/shdf/channels/740.html",
        },
        {
          name: "12318全国文化市场举报网站",
          url: "https://jbts.mct.gov.cn/",
        },
        {
          name: '隐私政策',
          url: '/privacyPolicy',
          type: 'self',
        }
      ],
    };
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      try {
        this.getZZTZurl();
      } catch (err) {}
    }, 3000);
  },
  methods: {
    openPage(item, index) {
      if (item.url) {
        if (item.type == "self") {
          if (item.url.indexOf("/aboutUs") >= 0 && (index == 0 || index == 3)) {
            this.$router.push({ path: item.url, query: { id: index } });
            // window.open(item.url + '?id=' + index, '_blank')
          } else {
            this.$router.push({ path: item.url });
          }
        } else {
          window.open(item.url, "_blank");
        }
      } else {
        if (item.classTop) {
          this.shishiClick(
            item.name.indexOf("客服") >= 0
              ? item.name.split("：")[1]
              : item.name
          );
        }
      }
    },
    //复制
    shishiClick(id) {
      var tag = document.createElement("input");
      tag.setAttribute("id", "cp_hgz_input");
      tag.value = id;
      document.getElementsByTagName("body")[0].appendChild(tag);
      document.getElementById("cp_hgz_input").select();
      document.execCommand("copy");
      document.getElementById("cp_hgz_input").remove();
      if (!this.messageFlag) {
        this.$message({
          type: "success",
          message: id + "复制成功",
          onClose: () => {
            this.messageFlag = false;
          },
        });
        this.messageFlag = true;
      }
    },
    getZZTZurl() {
      let url =
        document.getElementById("cnzz_stat_icon_1278896432") &&
        document.getElementById("cnzz_stat_icon_1278896432").childNodes[0].href;
      if (url != this.urlList[2].url) {
        this.urlList[2].url = url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: auto;
  background: #ebf4ff;
  position: relative;

  .footerCenter {
    width: 1070px;
    margin: 0 auto;
    height: 100%;
    .isxmbj02 {
      color: rgba(#fff, 0.5);
      .bold {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  .footerUrl {
    width: 100%;
    padding: 45px 0 30px;

    ul {
      display: flex;
      flex-wrap: wrap;
      text-align: center;

      li {
        margin-right: 23px;
        margin-bottom: 14px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #161616;
        display: flex;
        cursor: pointer;

        &:hover {
          color: #67a8f7;
        }

        &:nth-child(1) {
          padding-left: 63px;
          cursor: default;

          &:hover {
            color: #161616;
          }
        }

        &:nth-child(2) {
          cursor: default;
          &:hover {
            color: #161616;
          }
        }

        &:nth-child(3) {
          padding-right: 63px;
        }

        &:nth-child(11) {
          padding-left: 379px;
        }

        img {
          width: 18px;
          height: 20px;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
