<template>
  <a :class="['download', type]" @click="download" title="立即下载">
    <img src="../../assets/img/home/2022/download.png" />
    立即下载
  </a>
</template>
<script>
export default {
  props: ["type"],
  methods: {
    download() {
      this.$bus.$emit("downLoadPc");
    },
  },
};
</script>
<style scoped lang="scss">
.download {
  display: block;
  width: 244px;
  height: 74px;
  line-height: 74px;
  background: #3da7fe;
  border: 2px solid #2197f9;
  border-radius: 16px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  left: 310px;
  top: 378px;
  cursor: pointer;
  transition: all 300ms linear;
  img {
    width: 32px;
    height: 32px;
    display: block;
    float: left;
    margin: 21px 18px 21px 41px;
    // margin-right: 18px;
  }

  &:hover {
    background: #7ec5ff;
  }

  &.mini {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 40px;
    line-height: 38px;
    border: 2px solid #2197f9;
    border-radius: 8px;
    font-size: 16px;

    img {
      width: 16px;
      height: 16px;
      margin: 0px 9px 0px 0px;
      // margin-right: 8px;
    }
  }
}
</style>
