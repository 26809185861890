<!--  -->
<template>
<div class="corner">
    <div class="work" v-if="isWork">
        <i class="close" @click="close"></i>
    </div>
    <div class="QQ">
        <div class="zxkf" @click="goPage('https://wpa1.qq.com/SACxHVTT?_type=wpa&qidian=true')"></div>
        <div class="fsq" @click="goPage('https://shang.qq.com/wpa/qunwpa?idkey=6ce86494f476b5f654c7538d5e612dd9af04626b214b364449017fbb3a9e3d53')"></div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            isWork:true
        };
    },
    computed: {},
    methods: {
        close(){
            this.isWork = false
        },
        goPage(url){
            window.open(url,'_blank')
        }
    },
}
</script>

<style lang="scss" scoped>
.corner {
  .work {
    width: 172px;
    height: 201px;
    position: fixed;
    z-index: 999;
    right: 59px;
    bottom: 253px;
    background: url('../../assets/img/default/work.png') no-repeat;
    background-size: 100% 100%;
    i {
      position: absolute;
      width: 19px;
      height: 19px;
      background: url('../../assets/img/default/close.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      top: 47px;
      left: -6px;
    }
  }

  .QQ {
    position: fixed;
    z-index: 999;
    right: 59px;
    bottom: 70px;
    margin-top: 22px;
    margin-right: 23px;

    .zxkf {
      width: 80px;
      height: 80px;
      background: url('../../assets/img/default/zxkf.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;

      &:hover {
        background: url('../../assets/img/default/zxkf_a.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .fsq {
      width: 80px;
      height: 80px;
      background: url('../../assets/img/default/fsq.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;

      &:hover {
        background: url('../../assets/img/default/fsq_a.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
