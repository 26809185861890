import "babel-polyfill"
import promise from "es6-promise"
promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import router from './router'
import store from './store'

import lottie from 'vue-lottie';
import downLoadPc from './components/download'
Vue.component('downLoadPc', downLoadPc)

import Carousel3d from "vue-carousel-3d";
Vue.use(Carousel3d);

import {
  ParallaxContainer,
  ParallaxElement
} from 'vue-mouse-parallax'

Vue.component('parallax-container', ParallaxContainer)
Vue.component('parallax-element', ParallaxElement)

Vue.component('lottie', lottie)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')