<!--  -->
<template>
<div class="downLoadPC productbtn homeBtnWind" @click="downLoadPc" data-agl-cvt="6" v-if="versionType == 'baidu' || versionType == 'baidu_2'">
    <slot></slot>
</div>
<div class="downLoadPC productbtn homeBtnWind" @click="downLoadPc" qd="xmhh" v-else-if="versionType == 'xmhh'">
    <slot></slot>
</div>
<div class="downLoadPC productbtn homeBtnWind" @click="downLoadPc" id="qh1" v-else-if="versionType == '360'">
    <slot></slot>
</div>
<div class="downLoadPC productbtn homeBtnWind" @click="downLoadPc" data-agl-cvt="6" v-else-if="versionType == 'bilibili'">
    <slot></slot>
</div>
<div class="downLoadPC productbtn homeBtnWind sogou" @click="downLoadPc" v-else-if="versionType == 'sogou'">
    <slot></slot>
</div>
<div class="downLoadPC productbtn homeBtnWind" @click="downLoadPc" v-else>
    <slot></slot>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {
        versionType() {
            return this.$store.state.versionType
        }
    },
    methods: {
        downLoadPc() {
            this.$bus.$emit('downLoadPc')
        }
    },
}
</script>

<style lang='scss' scoped>
</style>
