<template>
  <div id="app">
    <div class="mainBody">
      <el-scrollbar ref="scrollbar">
        <router-view />
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { official } from '@/api/active'
export default {
  name: 'App',
  data() {
    return {
      showApp: false,
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      timer: false,
    }
  },
  created() {
    this.$store.dispatch('setDownLoadList', {})
    this.$store.dispatch('setVersion', '')
    this.$store.dispatch('setVersionType', 'upupoo')
  },
  computed: {
    downLoadList() {
      return this.$store.state.downLoadList
    },
    versionType() {
      return this.$store.state.versionType
    },
    version() {
      return this.$store.state.version
    },
  },
  mounted() {
    this.handleScroll()
    Vue.prototype.$scrollbar = this.$refs['scrollbar'];
    this.$bus.$off('goscrollbar')
    this.$bus.$on('goscrollbar', height => {
      this.$refs['scrollbar'].wrap.scrollTop = height
    })
    this.$bus.$off('downLoadPc')
    this.$bus.$on('downLoadPc', () => {
      this.downLoad()
    })
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    // this.$nextTick(() => {
    //   window.addEventListener('resize', () => (this.screenWidth = window.innerWidth), false)
    //   this.getSize()
    // })
  },
  // watch: {
  //   screenWidth(val) {
  //     // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
  //     if (!this.timer) {
  //       // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
  //       this.screenWidth = val
  //       this.timer = true
  //       let that = this
  //       setTimeout(function () {
  //         // 打印screenWidth变化的值
  //         that.getSize()
  //         console.log(that.screenWidth)
  //         that.timer = false
  //       }, 200)
  //     }
  //   },
  // },
  methods: {
    downLoad() {
      console.log(this.version, 'this.version')
      console.log(this.$route.query.n)
      let id = ''
      if (this.$route.query.tid && this.$route.path.indexOf('xmbj01') > -1) {
        id = this.$route.query.tid
      } else {
        if (this.$route.query.n && (this.versionType == 'baidu' || this.versionType == '360' || this.versionType == 'upupoo' || this.versionType == 'sugou')) {
          id = this.$route.query.n
        } else if (this.versionType == 'baidu') {
          id = '20200928020'
        } else if (this.versionType == '360') {
          id = '20200707006'
        } else if (this.versionType == 'bilibili') {
          id = '20201218026'
        } else if (this.versionType == 'sogou') {
          id = '20210412035'
        } else if (this.versionType == 'xmhh') {
          id = '10000420041'
        } else if (this.versionType == 'baidu_2') {
          id = '20210426043'
        }
      }
      if (!this.version) {
        let obj = {
          zsdd: id,
        }
        official(obj).then(res => {
          this.$store.dispatch('setVersion', res.data.version_no)
          this.$store.dispatch('setDownLoadList', res.data)
          this.startDownLoadUrl(res.data.version_no, res.data)
        })
      } else {
        this.startDownLoadUrl(this.version, this.downLoadList)
      }

      console.log('downLoad')
    },
    getSize() {
      console.log(this.$route.path, 'this.$route.path')
      if (window.innerWidth < 1200) return;
      if (this.$route.path == '/bd01' || this.$route.path == '/sg01' || this.$route.path == '/qh01' || this.$route.path == '/ad01') {
        return
      }
      if (window.innerWidth >= 1200 && this.$route.path != '/membership' && this.$route.path != '/realInfo') {
        let size = window.innerWidth / 1920
        document.getElementById('app').style.transform = 'scale(' + size + ')'
        document.getElementById('app').style.width = '1920px'
        document.getElementById('app').style.height = '936px'
      } else {
        document.getElementById('app').style.transform = 'scale(' + 1 + ')'
        document.getElementById('app').style.width = '100%'
        document.getElementById('app').style.height = '100%'
      }
    },
    startDownLoadUrl(version, data) {
      console.log('data:', data)
      const prefix = 'https://uft.upupoo.com/install_resource/'

      switch (this.versionType) {
        case 'baidu':
        case 'baidu_2':
          _czc.push(['_trackEvent', '下载', '点击下载PC客户端', '百度链接'])
          window.location.href = data.url || prefix + version + '/upupoo-installer_20200928020_.exe'
          break
        case '360':
          _czc.push(['_trackEvent', '下载', '点击下载PC客户端', '360链接'])
          window.location.href = data.url || prefix + version + '/upupoo-installer_20200707006_.exe'
          break
        case 'bilibili':
          _czc.push(['_trackEvent', '下载', '点击下载PC客户端', 'B站链接'])
          window.location.href = data.url || prefix + version + '/upupoo-installer_20201218026_.exe'
          break
        case 'sogou':
          _czc.push(['_trackEvent', '下载', '点击下载PC客户端', 'sogou链接'])
          window.location.href = data.url || prefix + version + '/upupoo-installer_20201218026_.exe'
          break
        case 'xmhh':
          _czc.push(['_trackEvent', '下载', '点击下载PC客户端', 'xmhh'])
          window.location.href = data.url || prefix + version + '/upupoo-installer_10000420041_.exe'
          break
        default:
          _czc.push(['_trackEvent', '下载', '点击下载PC客户端', '官网链接'])
          window.location.href = data.url || prefix + version + '/upupoo-installer.exe'
          break
      }
    },
    handleScroll() {
      let scrollbarEl = this.$refs.scrollbar.wrap
      scrollbarEl.onscroll = () => {
        if (scrollbarEl.scrollTop > 50) {
          this.$bus.$emit('showHeadBg', true)
        } else {
          this.$bus.$emit('showHeadBg', false)
        }
      }
    },
  },
}
</script>

<style>
@import url('./assets/style/common.css');
@import url('./assets/style/swiper.min.css');

body {
  font-size: 14px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1920px;
  height: 936px;
  min-width: 1200px;
  min-height: 585px;
  transform-origin: 0 0;
}

.mainBody {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss" scoped>
.el-scrollbar {
  width: 100%;
  height: 100%;

  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  /deep/ .el-scrollbar__bar {
    z-index: 9999;
  }
}
</style>
