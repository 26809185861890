<!--  -->
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    v-if="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="true"
    :modal-append-to-body="false"
    :show-close="false"
  >
    <div class="ifreamDom" v-if="typeVideo == 1">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        @play="onPlayerPlay($event)"
        @ready="playerReadied"
        :options="playerOptions"
      >
      </video-player>
    </div>
    <div class="ifreamDom" v-if="typeVideo != 1">
      <iframe
        frameborder="0"
        :src="ifreamUrl"
        width="100%"
        height="100%"
        id="html"
        ref="html"
        allowfullscreen="true"
      ></iframe>
    </div>
    <i class="closeModel" @click="handleClose" title="点击关闭"></i>
  </el-dialog>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  name: "videoModel",
  props: {
    dialogVisible: null,
    videoUrl: null,
    poster: null,
    typeVideo: null,
    ifreamUrl: null,
    aspectRatio:{
      type:String,
      default:"16:9"
    }
  },
  components: {
    videoPlayer,
  },
  data() {
    console.log(this.props)
    return {
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: this.aspectRatio, // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  computed: {},
  mounted() {
    let video = {
      type: "video/mp4",
      src: this.videoUrl,
    };
    this.playerOptions.sources = [];
    this.playerOptions.sources.push(video);
    this.playerOptions.poster = this.poster;
    this.onPlayerPlay();
  },
  methods: {
    handleClose() {
      this.$emit("closeModel");
    },
    playerReadied() {},
    onPlayerPlay() {
      if (this.$refs.videoPlayer) this.$refs.videoPlayer.player.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  /deep/ .vjs-big-play-button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/ .vjs-play-progress:before,
  /deep/ .vjs-volume-level:before {
    top: -0.43em;
  }
}

/deep/ .el-dialog {
  width: 1200px;
  height: 675px;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }
}

.video-player {
  width: 100%;
  height: 100%;
}

.ifreamDom {
  width: 100%;
  height: 675px;
}

.closeModel {
  position: absolute;
  // width: 38px;
  // height: 28px;
  // background: url('../../assets/img/default/closeModel.png') no-repeat;
  background-image: url("../../assets/img/home/2022/close.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  right: -54px;
  top: 0;
  cursor: pointer;

  &:hover {
    background-image: url("../../assets/img/home/2022/close_hover.png");
  }
}
</style>
