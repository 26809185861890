import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/',
    component: Layout,
    redirect: '',
    children: [
      // 首页
      {
        path: '',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        meta: {
          header: true,
          footer: false,
          corner: false,
        },
      },
      // {
      //   path: '/activation_code',
      //   name: 'activation_code',
      //   component: () => import('../views/activation/index.vue'),
      //   meta: {
      //     header: true,
      //     footer: true,
      //     corner: true,
      //   }
      // },
      {
        path: '/mobile_index',
        name: 'mobile_index',
        component: () => import('../views/wallpaper/index.vue'),
        meta: {
          header: true,
          footer: true,
          corner: true,
        },
      },
      {
        path: '/recommends',
        name: 'recommends',
        component: () => import('../views/recommends/index.vue'),
        meta: {
          header: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/realInfo_info',
        name: 'realInfo_info',
        component: () => import('../views/recommends/acticle.vue'),
        meta: {
          header: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/acticle_type',
        name: 'acticle_type',
        component: () => import('../views/recommends/typelist.vue'),
        meta: {
          header: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/searchresult',
        name: 'searchresult',
        component: () => import('../views/recommends/searchresult.vue'),
        meta: {
          header: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('../views/product/index.vue'),
        meta: {
          header: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/about',
        name: 'aboutUs',
        component: () => import('../views/aboutUs/index.vue'),
        meta: {
          header: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/about2',
        name: 'aboutUs2',
        component: () => import('../views/mobile/about.vue'),
        meta: {
          header2: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/mobile',
        name: 'mobile',
        component: () => import('../views/mobile/index.vue'),
        meta: {
          header2: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('../views/help/index.vue'),
        meta: {
          header: true,
          footer: true,
          corner: false,
        },
      },
      {
        path: '/dispute_resolution',
        name: 'dispute_resolution',
        component: () => import('../views/filePage/dispute_resolution.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/juveniles',
        name: 'juveniles',
        component: () => import('../views/filePage/juveniles.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/filing',
        name: 'filing',
        component: () => import('../views/filePage/filing.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import('../views/filePage/privacyPolicy.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/protocol_check',
        name: 'protocol_check',
        component: () => import('../views/filePage/protocol_check.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/membership_agreement',
        name: 'membership_agreement',
        component: () => import('../views/filePage/membership_agreement.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/miaobi_agreement',
        name: 'miaobi_agreement',
        component: () => import('../views/filePage/miaobi_agreement.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      // {
      //   path: '/xmbj01',
      //   name: 'xmbj01',
      //   component: () => import('../views/upupoo/xmbj01.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      // {
      //   path: '/xmbj02',
      //   name: 'xmbj02',
      //   component: () => import('../views/upupoo/xmbj02.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      // {
      //   path: '/xmqh02',
      //   name: 'xmqh02',
      //   component: () => import('../views/upupoo/xmqh02.vue'),
      //   meta: {
      //     header: false,
      //     footer: false,
      //     corner: false,
      //   },
      // },
      // {
      //   path: '/xmsg01',
      //   name: 'xmsg01',
      //   component: () => import('../views/upupoo/xmsg01.vue'),
      //   meta: {
      //     header: false,
      //     footer: false,
      //     corner: false,
      //   },
      // },
      // {
      //   path: '/xmqh01',
      //   name: 'xmqh01',
      //   component: () => import('../views/upupoo/xmqh01.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      // {
      //   path: '/xmbj',
      //   name: 'xmbj',
      //   component: () => import('../views/upupoo/xmbj.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      // {
      //   path: '/xmhh',
      //   name: 'xmhh',
      //   component: () => import('../views/upupoo/xmhh.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      {
        path: '/ad01',
        name: 'ad01',
        component: () => import('../views/channel/xmad01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/bd01',
        name: 'bd01',
        component: () => import('../views/channel/xmbd01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/qh01',
        name: 'qh01',
        component: () => import('../views/channel/xmqh01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/qh02',
        name: 'qh02',
        component: () => import('../views/channel/xmqh02.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/xn01',
        name: 'xn01',
        component: () => import('../views/channel/xmxn01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/mnbk',
        name: 'mnbk',
        component: () => import('../views/channel/mnbk.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/by01',
        name: 'by01',
        component: () => import('../views/channel/xmby01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/sg01',
        name: 'sg01',
        component: () => import('../views/channel/xmsg01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/protocol_index',
        name: 'protocol_index',
        component: () => import('../views/filePage/protocol_index.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/protocol_cdkey',
        name: 'protocol_cdkey',
        component: () => import('../views/filePage/protocol_cdkey.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/copyright_notice',
        name: 'copyright_notice',
        component: () => import('../views/filePage/copyright_notice.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/uploadRules',
        name: 'uploadRules',
        component: () => import('../views/filePage/uploadRules.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/membership',
        name: 'membership',
        component: () => import('../views/membership/index.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: '/realInfo',
        name: 'realInfo',
        component: () => import('../views/realInfo/index.vue'),
        meta: {
          header: true,
          footer: true,
          corner: true,
        },
      },
      // {
      //   path: '/realInfo_info',
      //   name: 'realInfo_info',
      //   component: () => import('../views/realInfo/info/index.vue'),
      //   meta: {
      //     header: true,
      //     footer: true,
      //     corner: true,
      //   },
      // },
      {
        path: '/realInfo_type',
        name: 'realInfo_type',
        component: () => import('../views/realInfo/infoType/index.vue'),
        meta: {
          header: true,
          footer: true,
          corner: true,
        },
      },
    ],
  },
  {
    path: '/channel',
    component: () => import('../views/channel/index.vue'),
    children: [{
        path: 'bd01',
        name: 'bd01',
        component: () => import('../views/channel/xmbd01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: 'qh01',
        name: 'qh01',
        component: () => import('../views/channel/xmqh01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      {
        path: 'sg01',
        name: 'sg01',
        component: () => import('../views/channel/xmsg01.vue'),
        meta: {
          header: false,
          footer: false,
          corner: false,
        },
      },
      // {
      //   path: 'xmbj02',
      //   name: 'xmbj02',
      //   component: () => import('../views/channel/xmbj02.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      // {
      //   path: 'xmqh02',
      //   name: 'xmqh02',
      //   component: () => import('../views/channel/xmqh02.vue'),
      //   meta: {
      //     header: false,
      //     footer: false,
      //     corner: false,
      //   },
      // },
      // {
      //   path: 'xmsg01',
      //   name: 'xmsg01',
      //   component: () => import('../views/channel/xmsg01.vue'),
      //   meta: {
      //     header: false,
      //     footer: false,
      //     corner: false,
      //   },
      // },
      // {
      //   path: 'xmqh01',
      //   name: 'xmqh01',
      //   component: () => import('../views/channel/xmqh01.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      // {
      //   path: 'xmbj',
      //   name: 'xmbj',
      //   component: () => import('../views/channel/xmbj.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
      // {
      //   path: 'xmhh',
      //   name: 'xmhh',
      //   component: () => import('../views/channel/xmhh.vue'),
      //   meta: {
      //     header: false,
      //     footer: true,
      //     corner: false,
      //   },
      // },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router