//统计
export function Statistics(type) {
    if (type == 'baidu' || type == 'baidu_2') {
        baidutj()
    } else if (type == '360') {
        Tongji360()
    } else if (type == 'bilibili') {
        bilibili()
    } else if (type == 'sogou') {
        sogou()
    } else if (type == 'xmhh') {

    } else {
        upupoo()
    }
}
//百度统计
function baidutj() {
    window._agl = window._agl || [];
    _agl.push(
        ['production', '_f7L2XwGXjyszb4d1e2oxPybgD']
    );
    var agl = document.createElement('script');
    agl.type = 'text/javascript';
    agl.async = true;
    agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(agl, s);
}
// 360统计
function Tongji360() {
    (function (b, a, e, h, f, c, g, s) {
        b[h] = b[h] || function () {
            (b[h].c = b[h].c || []).push(arguments)
        };
        b[h].s = !!c;
        g = a.getElementsByTagName(e)[0];
        s = a.createElement(e);
        s.src = "//s.union.360.cn/" + f + ".js";
        s.defer = !0;
        s.async = !0;
        g.parentNode.insertBefore(s, g)
    })(window, document, "script", "_qha", 378988, false);

    //cnzz统计 声明_czc对象:
    var _czc = window._czc || [];
    //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
    _czc.push(["_setAccount", "1278896432"]);
}

function bilibili() {
    //声明_czc对象:
    var _czc = window._czc || [];
    //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
    _czc.push(["_setAccount", "1278896432"]);
}

function upupoo() {
    //声明_czc对象:
    var _czc = window._czc || [];
    //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
    _czc.push(["_setAccount", "1278896432"]);
}

function sogou() {
    //声明_czc对象:
    var _czc = window._czc || [];
    //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
    _czc.push(["_setAccount", "1278896432"]);

}
export function alliance360(index) {
    try {
        let _czc = window._czc || [];
        let czcList = [
            ["_trackEvent", "360落地页", "向左切换壁纸", "左切换按钮"], //0
            ["_trackEvent", "360落地页", "向右切换壁纸", "右切换按钮"], //1
            ["_trackEvent", "360落地页", "点击中部壁纸", "中部壁纸切换"], //2
            ["_trackEvent", "360落地页", "点击网页壁纸", "网页壁纸"], //3
            ["_trackEvent", "360落地页", "试用", "网页壁纸"], //4
            ["_trackEvent", "360落地页", "点击鼠标指针", "鼠标指针"], //5
            ["_trackEvent", "360落地页", "试用", "鼠标指针"], //6
            ["_trackEvent", "360落地页", "点击音乐频谱", "音乐频谱"], //7
            ["_trackEvent", "360落地页", "点击蜂巢收纳", "蜂巢收纳"], //8
            ["_trackEvent", "360落地页", "拖动蜂巢", "蜂巢收纳"], //9
            ["_trackEvent", "360落地页", "点击激活码跳转", "激活码"], //10
        ]
        let name = JSON.parse(JSON.stringify(czcList[index]))
        console.log(name)
        _czc.push(name)

    } catch (err) {

    }

}
export function alliance(index) {
    try {
        let _czc = window._czc || [];
        let czcList = [
            ["_trackEvent", "百度落地页", "向左切换壁纸", "左切换按钮"], //0
            ["_trackEvent", "百度落地页", "向右切换壁纸", "右切换按钮"], //1
            ["_trackEvent", "百度落地页", "点击中部壁纸", "中部壁纸切换"], //2
            ["_trackEvent", "百度落地页", "点击网页壁纸", "网页壁纸"], //3
            ["_trackEvent", "百度落地页", "试用", "网页壁纸"], //4
            ["_trackEvent", "百度落地页", "点击鼠标指针", "鼠标指针"], //5
            ["_trackEvent", "百度落地页", "试用", "鼠标指针"], //6
            ["_trackEvent", "百度落地页", "点击音乐频谱", "音乐频谱"], //7
            ["_trackEvent", "百度落地页", "点击蜂巢收纳", "蜂巢收纳"], //8
            ["_trackEvent", "百度落地页", "拖动蜂巢", "蜂巢收纳"], //9
            ["_trackEvent", "百度落地页", "点击激活码跳转", "激活码"], //10

            ["_trackEvent", "官网", "点击进入", "顶部导航栏-首页"], //11
            ["_trackEvent", "官网", "点击进入", "顶部导航栏-激活码"], //12
            ["_trackEvent", "官网", "点击进入", "顶部导航栏-精选壁纸"], //13
            ["_trackEvent", "官网", "点击进入", "顶部导航栏-周边商城"], //14
            ["_trackEvent", "官网", "点击进入", "顶部导航栏-帮助中心"], //15

            ["_trackEvent", "官网", "播放软件介绍", "首屏-播放按钮"], //16

            ["_trackEvent", "官网", "切换至动态壁纸", "二屏-动态壁纸"], //17
            ["_trackEvent", "官网", "点击试用", "二屏-动态壁纸"], //18
            ["_trackEvent", "官网", "切换至鼠标指针", "二屏-鼠标指针"], //19
            ["_trackEvent", "官网", "点击试用", "二屏-鼠标指针"], //20
            ["_trackEvent", "官网", "切换至蜂巢收纳", "二屏-蜂巢收纳"], //21
            ["_trackEvent", "官网", "点击播放", "二屏-蜂巢收纳"], //22
            ["_trackEvent", "官网", "切换至音乐频谱", "二屏-音乐频谱"], //23
            ["_trackEvent", "官网", "点击播放", "二屏-音乐频谱"], //24
            ["_trackEvent", "官网", "切换至网页壁纸", "二屏-网页壁纸"], //25
            ["_trackEvent", "官网", "点击试用", "二屏-网页壁纸"], //26

            ["_trackEvent", "官网", "点击切换至创意动态", "三屏-创意动态"], //27
            ["_trackEvent", "官网", "点击切换至炫酷锁屏", "三屏-炫酷锁屏"], //28
            ["_trackEvent", "官网", "点击切换至个性来电", "三屏-个性来电"], //29
            ["_trackEvent", "官网", "点击切换至海量壁纸", "三屏-海量壁纸"], //30

            ["_trackEvent", "官网", "点击立即购买", "激活码-立即购买"], //31
            ["_trackEvent", "官网", "点击验证", "激活码-验证手机号"], //32
            ["_trackEvent", "官网", "点击验证", "激活码-验证邮箱"], //33
            ["_trackEvent", "官网", "切换壁纸", "精选壁纸-切换壁纸"], //34
        ]
        let name = JSON.parse(JSON.stringify(czcList[index]))
        console.log(name)
        _czc.push(name)

    } catch (err) {

    }

}