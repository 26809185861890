<!--  -->
<template>
<div class="box flex">
    <Header v-if="$route.meta && $route.meta.header" />
    <MobileHeader2 v-else-if="$route.meta && $route.meta.header2" />
    <main>
        <keep-alive v-if="$route.meta.keepAlive">
            <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
    </main>

    <videoModel :dialogVisible='dialogVisible' v-if="dialogVisible" :poster='poster' :videoUrl='videoUrl' :typeVideo='typeVideo' :ifreamUrl='ifreamUrl' @closeModel='closeModel'></videoModel>
    <Corner v-if="$route.meta && $route.meta.corner"></Corner>
    <Footer v-if="$route.meta && $route.meta.footer" />
</div>
</template>

<script>
import Header from "@/components/Header";
import MobileHeader2 from "@/components/Header/index2";
import Footer from "@/components/Footer";
import Corner from "@/components/Corner";
import videoModel from "@/components/videoModel";
export default {
    components: {
        Header,
        MobileHeader2,
        Footer,
        Corner,
        videoModel
    },
    data() {
        return {
            dialogVisible: false,
            videoUrl: '',
            typeVideo: '1', //种类
            poster: '', //封面
            ifreamUrl:'',
        };
    },
    created() {
        //打开视频弹窗
        this.$bus.$on('showVideoModel', (obj) => {
            console.log('showVideoModel')
            this.videoUrl = obj.url || ''
            this.poster = obj.poster
            this.typeVideo = obj.typeVideo
            this.ifreamUrl = obj.ifreamUrl || ''
            this.dialogVisible = true
        })
    },
    computed: {

    },
    methods: {
        //关闭视频弹窗
        closeModel() {
            this.videoUrl = ''
            this.poster = ''
            this.dialogVisible = false
        }
    },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 1300px;
  background-color: #fff;

  main {
    // height: 100%;
    // flex: 1;
    // padding-top: 64px;
  }
}
</style>
