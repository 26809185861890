<!--  -->
<template>
  <div>
    <div :class="['__header', scrollTop ? '___bg' : '']">
      <div class="header flex" ref="head_el">
        <div class="left_header flex" @click="$router.push({ path: '/' })">
          <img src="../../assets/img/header/logo_2022.png" />
        </div>
        <div class="right_header flex">
          <ul class="headerList flex">
            <!-- <li v-for="(item,index) in headerList" :key="index" :class="[activeClick == index?'active':'']" :style="{width:(item.name.length*20) +'px'}"> -->
            <li
              v-for="(item, index) in headerList"
              :key="index"
              :class="[activeRoute.path == item.url ? 'active' : '']"
              :style="{ width: item.name.length * 20 + 'px' }"
              :title="item.name"
            >
              <span
                @click="clickHeader(item, index)"
                v-if="item.type != 'download'"
                >{{ item.name }}</span
              >
              <DownloadBtn class="downapp" type="mini" v-else />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="header_block"></div>
  </div>
</template>

<script>
import DownloadBtn from "../../views/home/downloadbtn";
import { alliance } from "@/utils";
export default {
  data() {
    return {
      // activeClick: '0',
      activeRoute: {},
      scrollTop: false,
      headerList: [
        {
          name: "首页",
          url: "/",
          type: "home",
        },
        // {
        //     name: '激活码',
        //     url: '/activation_code',
        //     type: 'code',
        // },
        {
          name: "产品功能",
          url: "/product",
          type: "wallpaper",
        },
        // {
        //   name: '周边商城',
        //   active: 3,
        //   url: 'https://shop339533989.taobao.com/',
        //   type: 'shopping',
        // },
        {
          name: "精选推荐",
          url: "/recommends",
          type: "realInfo",
        },
        {
          name: "移动版",
          url: "/mobile",
          type: "mobile",
        },
        {
          name: "关于我们",
          // url: 'https://support.qq.com/products/57493',
          url: "/about",
          type: "about",
        },
        {
          name: "立即下载",
          url: "",
          type: "download",
        },
      ],
    };
  },
  created() {
    /*
    let url = this.$route.path
    let index = 0
    for (let i = 0; i < this.headerList.length; i++) {
      if (this.headerList[i].url && this.headerList[i].url == url) {
        index = i
        break
      }
    }
    
    this.activeClick = index
    this.$bus.$off('clearHead')
    this.$bus.$on('clearHead', () => {
      console.log('clearHead')
      this.activeClick = null
    })*/

    this.$bus.$off("goUrl");
    this.$bus.$on("goUrl", (item) => {
      // this.activeClick = item.index
      this.$router.push({ path: item.url });
    });
  },
  watch: {
    $route: {
      handler(newVal) {
        const route = { ...newVal };
        switch (route.path) {
          case "/realInfo_info":
          case "/realInfo_type":
            route.path = "/realInfo";
            break;
          case "/acticle":
          case "/acticle_type":
          case "/searchresult":
            route.path = "/recommends";
            break;
          default:
            break;
        }
        this.activeRoute = route;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$bus.$off("showHeadBg");
    this.$bus.$on("showHeadBg", (type) => {
      this.scrollTop = type;
    });

    let scrollbarEl = this.$scrollbar.wrap;
    scrollbarEl.addEventListener("scroll", (e) => {
      if (this.$refs.head_el) {
        let x = scrollbarEl.scrollLeft;
        this.$refs.head_el.style.transform = `translateX(-${x}px)`;
      }
    });
  },
  components: { DownloadBtn },
  computed: {},
  methods: {
    clickHeader(item, index) {
      // if (item.type == 'shopping' || item.type == 'help') {
      if (item.type == "shopping") {
        window.open(item.url, "_blank");
      } else if (item.type == "download") {
        this.$bus.$emit("downLoadPc");
      } else {
        // this.activeClick = index
        this.$router.push({ path: item.url });
      }
      if (index == 0) alliance(11);
      if (index == 1) alliance(12);
      if (index == 2) alliance(13);
      if (index == 3) alliance(14);
      if (index == 4) alliance(15);
      this.$scrollbar.wrap.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.__header {
  width: 100%;
  height: 93px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #ffffff;
  &.___bg {
    box-shadow: 1px 0 6px #ececec;
  }
}

.header_block {
  width: 100%;
  height: 93px;
  clear: both;
}
.header {
  min-width: 1300px;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 93px;
  .left_header {
    display: flex;
    cursor: pointer;
    img {
      width: 150px;
      height: 93px;
      margin-left: 50px;
    }
  }

  .nav_hover {
    font-size: 18px;
    font-weight: bold;
    color: #161616;
  }
  .right_header {
    display: flex;

    ul {
      display: flex;
      width: 691px;
      justify-content: space-between;
      margin-right: 100px;
      li {
        height: 93px;
        line-height: 93px;
        position: relative;
        cursor: pointer;
        transition: all 0.5s;

        span {
          text-align: center;
          display: block;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #161616;
          position: relative;
          z-index: 9;
          transition: all 0.5s;
        }

        &::before {
          width: 23px;
          height: 4px;
          content: " ";
          position: absolute;
          left: 50%;
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          bottom: 0;
          border-radius: 2px;
        }

        &:hover {
          span {
            @extend .nav_hover;
            font-size: 16px;
            color: #3da7fe;
          }
        }
        &.active {
          span {
            @extend .nav_hover;
          }
          &:hover {
            span {
              @extend .nav_hover;
              color: #3da7fe;
            }
          }
          &::before {
            background: #161616;
          }
        }
        .downapp {
          margin-top:26px;
        }
      }
    }
  }
}
</style>
