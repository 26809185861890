import request from '@/axios/index.js';

// 手机发送验证码
export function sendCheckCode(query) {
    return request({
        headers: {
            port: 9020
        },
        url: '/registerOrder/sendCheckCode',
        method: 'post',
        data: query
    })
}
//验证
export function checkCode(query) {
    return request({
        headers: {
            port: 9020
        },
        url: '/registerOrder/checkCode',
        method: 'post',
        data: query
    })
}
//获取二维码地址
export function registerOrder(query) {
    return request({
        headers: {
            port: 9020
        },
        url: '/registerOrder',
        method: 'post',
        data: query
    })
}

//获取价格
export function charge_business(query) {
    return request({
        headers: {
            port: 9020
        },
        url: '/charge_business/1',
        method: 'get',
        params: query
    })
}
//轮询
export function queryOrderSucc(id) {
    return request({
        headers: {
            port: 9020
        },
        url: '/registerOrder/queryOrderSucc/' + id,
        method: 'get',
    })
}

//获取手机下载二维码
export function qrcode(id) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/qr_code/qrcode',
        method: 'get',
    })
}

//回复列表页查询
// export function replyLists(query) {
//     return request({
//       headers: {
//         port: 9797
//       },
//       url: '/wallpaper/comment/children',
//       method: 'get',
//       params: query
//     })
//   }

//pc版本信息
export function official(query) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/qr_code/official',
        method: 'get',
        params: query
    })
}

//pc版本信息
export function officialV(query) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/qr_code/official',
        method: 'get',
        params: query
    })
}